import { useCurrentUser } from "@/features/auth/hooks/useCurrentUser"
import { useGetMyListsQuery } from "@/generated/graphql"
import graphqlRequestClient from "@/graphql/client"

export const useMyLists = () => {
  const { currentUser } = useCurrentUser()

  const { data, isLoading, error, refetch } = useGetMyListsQuery(
    graphqlRequestClient,
    {
      currentUserIdentifier: currentUser?.identifier ?? "",
    },
    {
      enabled: !!currentUser,
    },
  )

  return {
    lists: data?.lists || [],
    // If the user isn't logged in, we aren't really loading anything.
    loading: currentUser && isLoading,
    error,
    refetch,
  }
}
