import { Button, Checkbox, Heading, HStack, IconButton, Input, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, Text } from "@chakra-ui/react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import { redirectToAuth } from "supertokens-auth-react";
import { useCurrentUser } from "@/features/auth/hooks/useCurrentUser";
import { useMyLists } from "@/features/lists/hooks/useMyLists";
import { GetMyListsQuery, useSaveToListsMutation } from "@/generated/graphql";
import graphqlRequestClient from "@/graphql/client";
import PinIcon from "@/ui/icons/Pin";
import SnowdayIcons from "@/ui/icons/SnowdayIcons";
import { usePushQueryUpdate } from "@/utils/hooks/usePushQueryUpdate";
type List = GetMyListsQuery["lists"][0];
interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => Promise<unknown>;
  learningOpportunityIdentifier?: string;
  pathIdentifier?: string;
}
const SAVE_LO_IDENTIFIER_KEY = "save-lo-identifier";
const NEW_LIST_NAMES_KEY = "new-list-names";
const PRE_SELECTED_LIST_IDENTIFIERS_KEY = "pre-selected-list-identifiers";
export const SaveToListsModal = ({
  learningOpportunityIdentifier: initialLoId,
  pathIdentifier,
  isOpen,
  onClose,
  onSave
}: Props) => {
  const router = useRouter();
  const pushQueryUpdate = usePushQueryUpdate();
  const {
    currentUser
  } = useCurrentUser();
  const {
    lists,
    loading: loadingLists,
    refetch: refetchMyLists
  } = useMyLists();
  const queryLoId = (router.query[SAVE_LO_IDENTIFIER_KEY] as string | undefined);
  const loId = useMemo(() => {
    return initialLoId ?? queryLoId;
  }, [initialLoId, queryLoId]);
  const initialNewListNames = router.query[NEW_LIST_NAMES_KEY] ? Array.isArray(router.query[NEW_LIST_NAMES_KEY]) ? router.query[NEW_LIST_NAMES_KEY] : [router.query[NEW_LIST_NAMES_KEY]] : (undefined as string[] | undefined);
  const [newListNames, setNewListNames] = useState<string[]>(initialNewListNames ?? (lists.length > 0 ? [] : [""]));
  const hasEmptyNewListNames = useMemo(() => {
    return newListNames.some(name => !name || name.trim() === "");
  }, [newListNames]);
  const canDeleteNewList = useMemo(() => {
    return lists.length > 0 || newListNames.length > 1;
  }, [lists, newListNames]);
  const [saving, setSaving] = useState(false);
  const saveToListsMutation = useSaveToListsMutation(graphqlRequestClient);
  const [selectedListIdentifiers, setSelectedListIdentifiers] = useState(new Set<string>());

  // This is the set of lists saved into the query parameters. List identifiers may be set in the
  // query parameters to allow users to pick-up where they left off after signing up for an account.
  const previouslySelectedLists = router.query[PRE_SELECTED_LIST_IDENTIFIERS_KEY] ?? [];

  // Re-calculate which lists should be preselected based on changes to which LO we are saving,
  // what lists are available for a user, and the query parameters.
  const preSelectedListIdentifiers = useMemo(() => {
    const preSelectedLists = lists.filter(list => {
      return previouslySelectedLists.includes(list.identifier) || list.learningOpportunities.filter(lo => {
        return lo.identifier === loId;
      }).length > 0;
    });
    return new Set(preSelectedLists.map(list => list.identifier));
  }, [lists, loId]);
  const newlySelectedListIdentifiers = useMemo(() => {
    return Array.from(selectedListIdentifiers).filter(identifier => {
      return !preSelectedListIdentifiers.has(identifier);
    });
  }, [preSelectedListIdentifiers, selectedListIdentifiers]);
  const unselectedListIdentifiers = useMemo(() => {
    return Array.from(preSelectedListIdentifiers).filter(identifier => {
      return !selectedListIdentifiers.has(identifier);
    });
  }, [preSelectedListIdentifiers, selectedListIdentifiers]);

  // This covers cases where the lists an LO is saved to are updated, and then the modal is
  // re-opened. (E.g. the user wants to update the settings for an LO after saving it to a list.)
  useEffect(() => {
    if (!loId || lists.length === 0) {
      return;
    }
    setSelectedListIdentifiers(new Set(preSelectedListIdentifiers));
  }, [preSelectedListIdentifiers]);
  const numSelectedLists = selectedListIdentifiers.size + newListNames.length;
  const canSave = useMemo(() => {
    return (newlySelectedListIdentifiers.length > 0 || unselectedListIdentifiers.length > 0 || newListNames.length > 0) && !hasEmptyNewListNames;
  }, [newlySelectedListIdentifiers, unselectedListIdentifiers, hasEmptyNewListNames]);
  const handleClose = () => {
    setNewListNames(lists.length > 0 ? [] : [""]);
    pushQueryUpdate(SAVE_LO_IDENTIFIER_KEY, undefined);
    onClose();
  };
  const handleListToggled = (identifier: string, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      selectedListIdentifiers.add(identifier);
    } else {
      selectedListIdentifiers.delete(identifier);
    }
    setSelectedListIdentifiers(new Set(selectedListIdentifiers));
  };
  const handleCreateNewList = () => {
    setNewListNames([...newListNames, ""]);
  };
  const handleUpdateNewList = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      value
    } = event.target;
    newListNames[index] = value;
    setNewListNames([...newListNames]);
  };
  const handleDeleteNewList = (index: number) => {
    // We don't allow users to remove the last list.
    if (lists.length === 0 && newListNames.length === 1) {
      return;
    }
    newListNames.splice(index, 1);
    setNewListNames([...newListNames]);
  };
  const handleSave = async () => {
    // This should never happen, since the modal should not be open if there is no LO.
    if (!loId) {
      return;
    }
    if (!currentUser) {
      await router.replace({
        pathname: router.pathname,
        query: {
          ...router.query,
          identifier: pathIdentifier,
          [SAVE_LO_IDENTIFIER_KEY]: loId,
          [NEW_LIST_NAMES_KEY]: newListNames
        }
      }, undefined, {
        scroll: false
      });
      return redirectToAuth({
        show: "signup",
        redirectBack: true
      });
    }
    setSaving(true);
    await saveToListsMutation.mutateAsync({
      input: {
        authorIdentifier: currentUser.identifier,
        learningOpportunityIdentifier: loId,
        addToListIdentifiers: newlySelectedListIdentifiers,
        removeFromListIdentifiers: unselectedListIdentifiers,
        newListNames: newListNames.filter(name => name.trim() !== "")
      }
    });
    await onSave();
    refetchMyLists?.();
    setSaving(false);
    setNewListNames([]);
    return handleClose();
  };
  const renderContent = () => {
    return <>
        {renderLists()}
        {renderNewListInputs()}
      </>;
  };
  const renderLists = () => {
    if (loadingLists) {
      return <Spinner />;
    }
    return lists.map(list => renderList(list));
  };
  const renderList = (list: List) => {
    return <HStack key={list.identifier} justifyContent="space-between" mb={2} data-sentry-element="HStack" data-sentry-component="renderList" data-sentry-source-file="SaveToListsModal.tsx">
        <Text fontSize="md" data-sentry-element="Text" data-sentry-source-file="SaveToListsModal.tsx">{list.name}</Text>
        <Checkbox size="lg" isChecked={selectedListIdentifiers.has(list.identifier)} onChange={handleListToggled.bind(this, list.identifier)} data-sentry-element="Checkbox" data-sentry-source-file="SaveToListsModal.tsx" />
      </HStack>;
  };
  const renderNewListInputs = () => {
    return newListNames.map((name, index) => <HStack key={index} mb={2}>
        <Input autoFocus={true} placeholder="List Name" value={name} onChange={handleUpdateNewList.bind(this, index)} />
        <IconButton aria-label="Delete List" variant="transparent" icon={<SnowdayIcons name="TrashCan" cursor="pointer" boxSize={5} onClick={handleDeleteNewList.bind(this, index)} />} isDisabled={!canDeleteNewList} size="xs" />
      </HStack>);
  };
  const listNoun = numSelectedLists === 1 ? "List" : "Lists";
  return <Modal isOpen={loId !== undefined && (isOpen || queryLoId !== undefined)} onClose={handleClose} data-sentry-element="Modal" data-sentry-component="SaveToListsModal" data-sentry-source-file="SaveToListsModal.tsx">
      <ModalOverlay data-sentry-element="ModalOverlay" data-sentry-source-file="SaveToListsModal.tsx" />
      <ModalContent data-sentry-element="ModalContent" data-sentry-source-file="SaveToListsModal.tsx">
        <ModalHeader paddingBottom={5} data-sentry-element="ModalHeader" data-sentry-source-file="SaveToListsModal.tsx">
          <HStack data-sentry-element="HStack" data-sentry-source-file="SaveToListsModal.tsx">
            <PinIcon color="snow.blue-medium" boxSize={10} data-sentry-element="PinIcon" data-sentry-source-file="SaveToListsModal.tsx" />
            <Heading data-sentry-element="Heading" data-sentry-source-file="SaveToListsModal.tsx">Save to a List</Heading>
          </HStack>
        </ModalHeader>
        <ModalCloseButton data-sentry-element="ModalCloseButton" data-sentry-source-file="SaveToListsModal.tsx" />
        <ModalBody overflowY="auto" paddingBottom={8} data-sentry-element="ModalBody" data-sentry-source-file="SaveToListsModal.tsx">
          {renderContent()}
          <HStack alignItems="center" justifyContent="space-between" mt={4} data-sentry-element="HStack" data-sentry-source-file="SaveToListsModal.tsx">
            <Button variant="transparent" color={saving ? "snow.blue-light" : "snow.blue-light-medium"} flexBasis={100} isDisabled={saving || hasEmptyNewListNames} onClick={handleCreateNewList} leftIcon={<SnowdayIcons name="CirclePlus" color={saving ? "snow.blue-light" : "snow.blue-light-medium"} boxSize={6} />} data-sentry-element="Button" data-sentry-source-file="SaveToListsModal.tsx">
              New List
            </Button>
            <Button onClick={handleSave} isDisabled={loadingLists || !canSave} isLoading={saving} flexBasis={150} data-sentry-element="Button" data-sentry-source-file="SaveToListsModal.tsx">
              Save to {listNoun}
            </Button>
          </HStack>
          {!currentUser && <Text color="snow.silver" fontSize="sm" textAlign="right" width="100%" mt={4} mb={-4}>
              Don't see your lists?{" "}
              <Link as={NextLink} href="/auth" textDecoration="underline">
                Login
              </Link>
            </Text>}
        </ModalBody>
      </ModalContent>
    </Modal>;
};